<template>
  <div class="wrap">
    <headers></headers>
    <router-view />
    <footers></footers>
  </div>

  
</template>
<script>

import headers from './components/header.vue'
import footers from './components/footers.vue'
export default {
  components:{
    headers,
    footers
  }
}
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wrap{
  padding-top: 100px;
}
</style>
