<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-top-wrap">
        <div class="logo-img">
          <router-link to="/">
            <img src="../assets/images/home/tail_logo.png" alt />
          </router-link>
        </div>
        <div class="footer-menu-box">
          <div class="menu-item">
            <div class="item-tit"><router-link to="/">首页</router-link></div>
          </div>
          <div class="menu-item">
            <div class="item-tit"><router-link to="/partners">合作共创</router-link></div>
          </div>
          <div class="menu-item">
            <div class="item-tit item-link" style="text"  @click="jumpPage('/contactUs',1)">联系我们</div>
            
          </div>
          
          
          <div class="menu-item">
            <div class="item-tit">行业动态</div>
            <div class="item-menu" @click="jumpPage('/groupDynamics',1)">公司资讯</div>
            <div class="item-menu" @click="jumpPage('/groupDynamics',2)">数字快报</div>
          </div>
          <div class="menu-item">
            <div class="item-tit">企业概述</div>
            <div  class="item-menu" @click="jumpPage('/about',1)">公司介绍</div>
            <div  class="item-menu" @click="jumpPage('/about',2)">公司高层</div>
            <div  class="item-menu" @click="jumpPage('/about',3)">成就长廊</div>
          </div>
          <div class="qr-box">
            <div class="qr-tit">关注公众号</div>
            <div class="qr-img">
              <img src="../assets/images/qr-code.png" alt="">
            </div>
          </div>
          
          
        </div>
        
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-wrap">
        <p>
          <span>闽网文（2024）1358-026号</span>
          <b>|</b>
          <span>ICP证:闽B2-20230344</span>
          <b>|</b>
          <span><a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2024031087号-1</a></span>
        </p>
        <p>
          <span>泉州宇宙派队网络科技有限公司版权所有©2018-2023。</span>
          <b>|</b>
          <span>地址：福建省泉州经济技术开发区崇宏街463号办公楼3-5楼</span>
        </p>
        
        <p>
          <span>亲爱的市民朋友，福建警方反诈劝阻电话“96110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。</span>
        </p>
        <p>
          <span>违法和不良信息举报电话:4009936669</span>
          <b>|</b>
          <span>文化部网络游戏举报和联系电子邮箱：wlyxjb@gmail.com</span>
          <b>|</b>
          <span>扫黄打非举报</span>
        </p>
        <div class="bottom-line">
          <!-- <div class="line-item">
            <img src="../assets/images/wenhua.png" alt="">
            <span>互联网文化经营单位</span>
          </div> -->
          <div class="line-item">
            <a href="https://www.12377.cn/" target="_blank">
              <img src="../assets/images/youhaijubao.png" alt="">
              <span>网上有害信息举报专区</span>
            </a>
            
          </div>
          <div class="line-item">
            <a href="http://jubao.fjsen.com" target="_blank">
              <img src="../assets/images/b-jubao.png" alt="">
              <span>福建省互联网违法和<br />不良信息举报中心</span>
            </a>
            
          </div>
          <!-- <div class="line-item">
            <img src="../assets/images/wangan.png" alt="">
            <span>闽公安备案<br />31010402004903号</span>
          </div> -->
          <div class="line-item">
            <a href="https://www.beian.gov.cn/" target="_blank">
              <img src="../assets/images/wenhua.png" alt="">
              <span>福建警方反诈劝阻电话<br />96110</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { getCurrentInstance, ref, reactive, defineComponent, toRefs, onBeforeMount,onMounted } from 'vue';
import moment from "moment"; 
import homeApi from '../api/api.js'
import { useRoute,useRouter } from 'vue-router'
import Bus from '../common/bus'
export default {
  name: "footers",
  setup() {
    const router = useRouter();
    const jumpPage = (path,tabIndex) => {
      document.body.scrollIntoView()
      if(tabIndex){
        
        console.log(window.location.hash)
        if(window.location.hash.indexOf(path)>-1){
          console.log("当前页")
          Bus.$emit('changeTabIndex',{tabIndex:tabIndex-1});
        }else{
          router.push({
              path: path,
              query:{
                tabIndex:tabIndex-1
              }
          });
        }
       
      }else{
        router.push({
            path: path,
        });
      }
    }

    return {
      jumpPage
    }
  }
};
</script>
<style scoped lang="scss">
.footer {
  .footer-top {
    display: flex;
    width: 100%;
    height: 400px;
    background: #ffffff;
    border-bottom: 1px solid #f3f4f9;
    .footer-top-wrap {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
    }
    .logo-img {
      width: 192px;
      height: 147px;
      margin-top: 84px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .footer-menu-box {
      display: flex;
      margin-top: 84px;
      .menu-item {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        .item-tit {
          font-size: 20px;
          font-family: "微软雅黑";
          color: #7A8497;
          margin-bottom: 28px;
          font-weight: 600;
          width: 146px;
          a{
            color: #7A8497;
            text-decoration: none;
          }
          a:hover{
            text-decoration: underline;
          }
        }
        .item-link{
          text-decoration: none;
          cursor: pointer;
        }
        .item-link:hover{
          text-decoration: underline;
        }
        .item-menu {
          font-size: 20px;
          font-family: "微软雅黑";
          font-weight: 400;
          color: #7A8497;
          margin-bottom: 28px;
          cursor: pointer;
          text-decoration: none;
          width: 146px;
        }
        .item-menu:hover{
            text-decoration: underline;
        }
      }
      .qr-box{
        display: flex;
        flex-direction: column;
        .qr-tit{
          font-family: MiSans, MiSans;
          font-weight: 400;
          font-size: 24px;
          color: #7A8497;
          margin-bottom: 22px;
        }
        .qr-img{
          width: 183px;
          height: 183px;
          img{
            width: 100%;
            height: 100%;
          }
        }

      }
    }
  }
  .footer-bottom {
    width: 100%;
    background: #ffffff;
    .footer-bottom-wrap {
      width: 1200px;
      margin: 0 auto;
      font-size: 20px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #cfd6e9;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      a{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #66697B;
        text-decoration: none;
        cursor: pointer;
      }
      p{
        display: flex;
        align-content: center;
        font-size: 12px;
        color: #66697B;
        margin-bottom: 10px;
        span{
          font-size: 12px;
          color: #66697B;
        }
        b{
          margin: 0 10px;
        }
      }
      .bottom-line{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .line-item{
          display: flex;
          align-items: center;
          margin-right: 40px;
          a{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #66697B;
            text-decoration: none;
          }
          img{
            width: 30px;
            height: 30px;
            margin-right: 20px;
          }
          span{
            font-size: 12px;
            color: #66697B;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>