<template>
  <div class="header">
    <div class="header-wrap">
      <div class="logo">
        <router-link to="/">
          <img src="../assets/images/home/top_logo.png" />
        </router-link>
      </div>
      <div class="r-menu">
        <router-link :to="item.path" class="item-menu" :class="[[index == data.tabIndex ? 'menu-active':'']]"  @click="clickMenu(index)" v-for="(item,index) in data.menuList" :key="index">{{ item.name }}</router-link>
        <div class="slide" :style="data.move"></div>
        <!-- <router-link to="/about" class="item-menu">集团概述</router-link>
        <div  class="item-menu item-menu-product" @mouseover="showMenu = true" @mouseleave="showMenu = false">
          <span>产品中心</span>
          <div class="product-list" v-if="showMenu">
            <div class="product-list-item" @click="jumpPage('/dapaidui')">宇宙大派队</div>
            <div class="product-list-item" @click="jumpPage('/fengchao')">源之蜂巢数据库</div>
            <div class="product-list-item" @click="jumpPage('/longxiang')">龍翔手游</div>
            
          </div>
          
        </div>
        <router-link to="/groupDynamics" class="item-menu">集团动态</router-link>
        <a class="item-menu" href="https://shop.fuhua52.com/" target="_blank">周边手办</a>
        <router-link to="/joinUs" class="item-menu">联系我们</router-link> -->
      </div>
    </div>
  </div>
</template>
<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import {
  getCurrentInstance,
  watch,
  ref,
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted
} from "vue";


  const data = reactive({
    showMenu:false,
    isProduct:false,
    menuList:[
      {
        name:'首页',
        path:'/'
      },
      {
        name:'宇宙大派队',
        path:'/dapaidui'
      },
      {
        name:'企业概览',
        path:'/about'
      },
      {
        name:'合作共创',
        path:'/partners'
      },
      {
        name:'行业动态',
        path:'/groupDynamics'
      },
      {
        name:'联系我们',
        path:'/contactUs'
      },
    ],
    move:'left:50px',
    MoveCurrent:0,
    tabIndex:0
  })
  const router = useRouter();
  const jumpPage = (path) => {
    data.showMenu = false
    router.push({
        path:path,
        
    });
  }


    // // 鼠标经过
    // const slideMove = (idx) => {
    //   data.MoveCurrent = idx
    //   data.move = 'left:' + (203 * idx + 77) + 'px;height:4px;'
    // }
    // // 鼠标移除时
    // const slideOut = (idx) => {
    //   data.move = 'left:' + (203 * idx + 77) + 'px;height:4px;'
    // }
    const clickMenu = (idx) => {
      data.move = 'left:' + (153 * idx + 50) + 'px;height:4px;'
      data.tabIndex = idx
      data.MoveCurrent = idx
    }

  watch(
    () => router.currentRoute.value,
    (newValue) => {
      console.log(newValue,'newValue')
      document.body.scrollIntoView()
      data.menuList.forEach((item,index)=>{
        if(newValue.path == item.path){
          data.tabIndex =  index
          data.move = 'left:' + (153 * index + 50) + 'px;height:4px;'
        }
      })
      console.log('newValue',newValue)
    },
    { immediate: true }
  )
</script>
<style scoped lang="scss">


.header {
  height: 100px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  background: #FFFFFF;
  box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.1);
  .header-wrap {
    width: 1300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      width: 242px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .r-menu {
      display: flex;
      align-items: center;
      position: relative;
      .slide {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 77px;
        height: 4px;
        background: #2E5BF3;
        border-radius: 9px 9px 9px 9px;
        transition: all .3s;
      }
      .item-menu {
        margin-left: 20px;
        font-family: MiSans, MiSans;
        font-weight: 600;
        font-size: 24px;
        color: #0F1F56;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        width: 134px;
        text-align: center;
        .product-list{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 140px;
          z-index: 99999999999999;
          height: 0px;
          .product-list-item{
            width: 140px;
            height: 60px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-family: Microsoft Sans Serif-Regular, Microsoft Sans Serif;
            font-weight: 400;
            color: #333333;
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
            text-align: center;
          }
          .product-list-item:hover{
            color: #1E67FC;
            background: #F2F2F2;

          }
        }
      }
      .item-menu:hover {
        color: #2E5BF3;
        font-weight: bold;
      }
      .menu-active {
        color: #2E5BF3;
        font-weight: bold;
      }
    }
  }
}


</style>