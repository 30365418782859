import { service } from '../request/request'

const homeApi = {
    // 首页banner图
    homeBannerListApi(id){
        return service.get(`/home/slides/${id}`)
    },
    //获取文章分类
    articleTypeApi(){
        return service.get(`/portal/categories`)
    },

    

    getNewListApi(id,params){
        return service.get(`/portal/articles/category/${id}`,{params})
    },
    getNewDetailApi(id){
        return service.get(`/portal/articles/${id}`)
    },
    //公司介绍
    companyIntroduceApi(){
        return service.get(`/portal/pages/100000`,)
    },

    achievementListApi(){
        return service.get(`/portal/pages/100001`,)
    },

    
    //获取职位分类
    joinTypeApi(params){
        return service.get('/portal/PositionCategory',{
            params:params
        })
    },
    //获取职位列表
    positonListApi(params){ 
        return service.get('/portal/position/getByCategory',{params})
    },
    //获取职位详情
    positonDetailApi(params){ 
        return service.get('/portal/position/detail',{
            params:params
        })
    },

    //上传文件
    uploadApi(formData){
        return service({
            method:'post',
            url:'/portal/index/upload',
            data:formData,
            headers:{
                "Content-Type": "multipart/form-data"
            }
        })
    },
    //简历上传
    resumePostApi(params){ 
        return service.post('/portal/position/user',params)
    },

    

    



    
}


export default homeApi