<template>
  <div class="home">
    <div class="top-banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide swipe-items" v-for="(item,index) in data.bannerList" :key="index">
            <img :src="item.imgUrl" class="banner-img" alt />
            <!-- <el-image class="banner-img" :src="item.imgUrl" /> -->
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
    <div class="publicity-box">
      <div class="publicity-tit reveal-top">企宣视频</div>
      <div class="publicity-video-box reveal-top">
        <video width="1100" height="630" controls autoplay>
            <source src="../assets/images/home/66666.mp4" type="video/mp4">
        </video>
      </div>
      <div class="main-data-box">
        <div class="main-data-item data-1 reveal-top" >
          <div class="main-data-item-content">
            <el-statistic :value-style="data.textStyle" suffix="年+" class="main-text" :value="yearValue">
              <template #suffix>
                 <div class="suffix-text">年+</div>
              </template>
            </el-statistic>
            <span>行业深耕</span>
          </div>
        </div>

        <div class="main-data-item data-2 reveal-top">
          <div class="main-data-item-content" >
            <el-statistic :value-style="data.textStyle" suffix="年+" class="main-text" :value="companyValue">
              <template #suffix>
                 <div class="suffix-text">+</div>
              </template>
            </el-statistic>
            <span>子公司</span>
          </div>
        </div>

        <div class="main-data-item data-3 reveal-top">
          <div class="main-data-item-content">
            <el-statistic :value-style="data.textStyle" suffix="年+" class="main-text" :value="knowledgeValue">
              <template #suffix>
                 <div class="suffix-text">+</div>
              </template>
            </el-statistic>
            <span>知识产权</span>
          </div>
        </div>

        <div class="main-data-item data-4 reveal-top">
          <div class="main-data-item-content">
            <el-statistic :value-style="data.textStyle" suffix="年+" class="main-text" :value="partnerValue">
              <template #suffix>
                 <div class="suffix-text">个</div>
              </template>
            </el-statistic>
            <span>合作商</span>
          </div>
        </div>
        
      </div>
    </div>

    <div class="scence-box">
      <div class="scence-tit reveal-introduce">
        六大方向呈现场景
      </div>
      <div class="secnce-tab-box">
        <div class="scence-tab-tit">
          <div class="scence-tab-tit-item"  @click="scencceTabChange(item,index)" :class="[[data.scenceIndex == index?'scence-active':'']]" v-for="(item,index) in data.scenceList" :key="index">
            <img :src="item.imgPath" alt="">
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="tab-video-box reveal-top">
          <video width="1100" height="630" controls autoplay>
              <source src="../assets/images/home/66666.mp4" type="video/mp4">
          </video>
        </div>
        <div class="tab-tip-text reveal-top">
          {{ data.scenceList[data.scenceIndex].tipText }}
        </div>
      </div>
      
    </div>
   
    
  </div>
</template>

<script setup>
// @ is an alias to /src
import {
  getCurrentInstance,
  ref,
  reactive,
  defineComponent,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted,
  onBeforeUnmount
} from "vue";
import { useTransition } from '@vueuse/core'
import qs from "qs";
import homeApi from "../api/api.js";
import axios from "axios";
import { useRouter } from "vue-router";
//import { test } from '../common/test.js'
import retScroll from '@/common/retScroll.js'
import scrollReveal from 'scrollreveal'

    const data = reactive({
      textStyle:{
        fontFamily: 'MiSans, MiSans',
        fontWeight: 800,
        fontSize:' 56px',
        color: '#0F1F56',
      },
      bannerList: [
        {
          name: "banner1",
          //imgUrl:'https://gwcdn.fuhua95.com/pc/banner1.jpg'
          imgUrl: require("../assets/images/home/home_banner.png")
        },
      ],
      newList:[],
      
      newIndex:0,
      
      articleList:[],
      firstArticle:{},
      scenceList:[
        {
          imgPath:require("../assets/images/home/01.png"),
          name:'乡村情怀',
          tipText:'还原八九十年代农场的生活场景，展示乡村的自然风光，文化遗产和传统工艺。'
        },
        {
          imgPath:require("../assets/images/home/02.png"),
          name:'虚拟神话',
          tipText:'在传统神话虚拟场景之中，真切感受流传千年的传统神话。'
        },
        {
          imgPath:require("../assets/images/home/03.png"),
          name:'历史朝代',
          tipText:'还原历史朝代，呈现人类过去的生活场景，让消失、破损的古建筑再度重现。'
        },
        {
          imgPath:require("../assets/images/home/04.png"),
          name:'文旅场景',
          tipText:'复刻现有的各地方地标建筑景点，促进各地文旅宣传，丰富景点意义。'
        },
        {
          imgPath:require("../assets/images/home/05.png"),
          name:'未来生活',
          tipText:'探索未来世界的生活场景，建设未来之城，在未来城一切皆有可能。'
        },
        {
          imgPath:require("../assets/images/home/06.png"),
          name:'休闲娱乐',
          tipText:'打造各类休闲娱乐场景，享受数字休闲方式。'
        },
      ],
      scenceIndex:0,
      scrollReveal: scrollReveal()

    });
    const sourceYear = ref(0)
    const yearValue = useTransition(sourceYear, {
      duration: 1500,
    })
    sourceYear.value = 6

    const sourceCompany = ref(0)
    const companyValue = useTransition(sourceCompany, {
      duration: 1500,
    })
    sourceCompany.value = 20

    
    const sourceKnowledge = ref(0)
    const knowledgeValue = useTransition(sourceKnowledge, {
      duration: 1500,
    })
    sourceKnowledge.value = 300

    const sourcePartner = ref(0)
    const partnerValue = useTransition(sourcePartner, {
      duration: 1500,
    })
    sourcePartner.value = 1000

    const scencceTabChange = (item,index) => {
      data.scenceIndex = index
    }

    //获取文章分类
    const getTypeList = () => {
      
      homeApi.articleTypeApi().then(res => {
        console.log(res);
        console.log("666res");
        if (res.code == 1) {
          // data.bannerList = res.data[0].items
          let arr = res.data
          let newArr = arr.sort((a,b)=> {
            return - (a.list_order - b.list_order)
          })
          console.log(newArr,'newArr===')
          data.newList = arr
          getNewList(arr[0].id)
        }
      });
    }
    //获取文章列表
    const getNewList = (id) => {
      //?page=2&limit=5
      let params = {
        page:1,
        limit:10
      }
      homeApi.getNewListApi(id,params).then(res => {
        console.log(res)
        console.log("666res")
        if(res.code == 1){
          
          let arr = res.data.list.data
          data.firstArticle = res.data.list.data[0]
          let newArr = []
          arr.forEach((element,index) => {
            if(index>0 && index<5){
              newArr.push(element)
            }
          });
          data.articleList = newArr
        }
      })
    }
    // 跳转文章详情
    const router = useRouter();
    
    const jumpPage = (path) => {
      router.push({
          path: path,
         
      });
    }
    const jumpArticleDetail = (item) => {
      if(item.type == 1){
        window.open(item.url)
      }else{
        router.push({
            path: "/groupDynamicsDetail",
            query:{
              id:item.id
            }
        });
      }
      
    }

   

   
    
    onMounted(() => {

      retScroll(data)
      
      getTypeList()
      const mySwiper = new Swiper(".swiper-container", {
        autoplay: 5000,
        // loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:"fade",
        // effect:"cube",
        // 如果需要分页器
        pagination: ".swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev"
      });
      const productSwiper = new Swiper(".product-swiper-container", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        // effect:"fade",
        // effect:"cube",
        // 如果需要分页器
        pagination: ".product-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".product-swiper-button-next",
        prevButton: ".product-swiper-button-prev"
      });

      const gameSwiper = new Swiper(".game-swiper-box", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        
        // 如果需要分页器
        pagination: ".game-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".game-swiper-button-next",
        prevButton: ".game-swiper-button-prev",
        // onSlideChangeEnd: function(swiper){
        //   //console.log(swiper.activeIndex)
        //   // let index = swiper.activeIndex-1
        //   // if(index == -1){
        //   //   index = 1
        //   // }
        //   // if(index == 2){
        //   //   index = 0
        //   // }
        //   // data.gameBg = data.gameList[index].bgUrl
        //   console.log(index)

          
        // }
      });

      const gongyiSwiper = new Swiper(".gongyi-swiper-box", {
        // autoplay: 2000,
        loop: true,
        grabCursor: true,
        paginationClickable: true,
        
        // 如果需要分页器
        pagination: ".gongyi-swiper-pagination",

        // 如果需要前进后退按钮
        nextButton: ".gongyi-swiper-button-next",
        prevButton: ".gongyi-swiper-button-prev",
        
      });
      
      getBannerList();

   
    });

    const getBannerList = () => {
      let id = 1;
      homeApi.homeBannerListApi(id).then(res => {
        console.log(res);
        console.log("666res");
        if (res.code == 1) {
          // data.bannerList = res.data[0].items
        }
      });
    };

    const newTab = (item,index) => {
       data.newIndex = index
       console.log(item)
       getNewList(item.id)
    }
</script>
<style lang="scss">
.top-banner {
  .swiper-pagination-bullets {
    bottom: 50px;
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #ffffff;
    opacity: 0.7;
    transition: width 0.5s ease-in-out;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
.product-swiper-pagination{
  
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
.hot-game-wrap{
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
.gongyi-wrap{
  .swiper-pagination-bullet {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #1E67FC;
    opacity: 0.2;
    transition: width 0.5s ease-in-out;
    margin: 0 5px;
  }
  .swiper-pagination-bullet-active {
    width: 120px;
    opacity: 1;
  }
}
</style>
<style scoped lang="scss">
.home {
  background:#E7EDFF url("../assets/images/home/home_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  .top-banner {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .swiper-container {
      width: 100%;
      height: 800px;
    }

    .banner-swipe {
      width: 100%;
    }
    .demonstration {
      color: var(--el-text-color-secondary);
    }

    .el-carousel__item h3 {
      color: #475669;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
      text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .swipe-items {
      width: 100%;
      height: 800px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .tit {
      font-size: 88px;
      font-family: "微软雅黑";
      font-weight: 600;
      color: #f3f3f3;
    }
    .about-btn {
      width: 500px;
      height: 97px;
      background: rgba(30, 103, 252, 0.5);
      border-radius: 5px 5px 5px 5px;
      font-size: 40px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .publicity-box{
    margin-top: 172px;
    .publicity-tit{
      width: 325px;
      height: 99px;
      background: url("../assets/images/home/title-1.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 99px;
      font-family: MiSans, MiSans;
      font-weight: 800;
      font-size: 42px;
      color: #0F1F56;
      margin: 0 auto;
      margin-bottom: 62px;
    }
    .publicity-video-box{
      width: 1200px;
      height: 715px;
      margin: 0 auto;
      background: url("../assets/images/home/content-1.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .main-data-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      margin-top: 50px;
      .main-data-item{
        height: 316px;
        width: 280px;
        .main-data-item-content{
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .main-text{
            font-family: MiSans, MiSans;
            font-weight: 800;
            font-size: 56px;
            color: #0F1F56;
            .suffix-text{
              font-family: MiSans, MiSans;
              font-weight: 800;
              font-size: 56px;
              color: #0F1F56;
            }
          }
        }
      }
      .data-1{
        background: url("../assets/images/home/n1.png") no-repeat top center;
        background-size: 228px 228px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .main-data-item-content{
          width: 280px;
          height: 210px;
          background: url("../assets/images/home/bg1.png") no-repeat top center;
          border-radius: 20px 20px 20px 20px;
        }
      }
      .data-2{
        background: url("../assets/images/home/n2.png") no-repeat top center;
        background-size: 228px 228px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .main-data-item-content{
          width: 280px;
          height: 210px;
          background: url("../assets/images/home/bg2.png") no-repeat top center;
          border-radius: 20px 20px 20px 20px;
        }
      }
      .data-3{
        background: url("../assets/images/home/n3.png") no-repeat top center;
        background-size: 228px 228px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .main-data-item-content{
          width: 280px;
          height: 210px;
          background: url("../assets/images/home/bg3.png") no-repeat top center;
          border-radius: 20px 20px 20px 20px;
        }
      }
      .data-4{
        background: url("../assets/images/home/n4.png") no-repeat top center;
        background-size: 228px 228px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .main-data-item-content{
          width: 280px;
          height: 210px;
          background: url("../assets/images/home/bg4.png") no-repeat top center;
          border-radius: 20px 20px 20px 20px;
        }
      }
    }
  }
  .scence-box{
    margin-top: 184px;
    .scence-tit{
      width: 505px;
      height: 99px;
      background: url("../assets/images/home/title-2.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 99px;
      font-family: MiSans, MiSans;
      font-weight: 800;
      font-size: 42px;
      color: #0F1F56;
      margin: 0 auto;
      margin-bottom: 62px;
    }
    .secnce-tab-box{
      width: 1200px;
      margin: 0 auto;
      .scence-tab-tit{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .scence-tab-tit-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          img{
            width: 66px;
            height: 54px;
          }
          span{
            font-family: MiSans, MiSans;
            font-weight: 400;
            font-size: 42px;
            color: #485272;
          }
        }
        .scence-active::after{
          content: '';
          height: 8px;
          width: 84px;
          background: #485cf8;
          border-radius: 8px;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -42px;
        }
        .scence-active{
          span{
            font-family: MiSans, MiSans;
            font-weight: 800;
            font-size: 42px;
            color: #0F1F56;
          }
        }
      }
      .tab-video-box{
        width: 1200px;
        height: 715px;
        margin: 0 auto;
        background: url("../assets/images/home/content-1.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 56px;
      }
      .tab-tip-text{
        width: 1091px;
        height: 78px;
        background: url("../assets/images/home/title-2.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 78px;
        font-family: MiSans, MiSans;
        font-weight: 400;
        font-size: 28px;
        color: #0F1F56;
        margin: 0 auto;
        margin-bottom: 150px;
        margin-top: 70px;
      }
    }
    
  }
  
  
}
</style>
